// {Login.tsx}

import React from 'react';
import { Login as LoginTemplate } from 'quil';
// import useStyles from './styles';
// import TEST_ID from './constants';

export interface LoginProps {
  onLoginSso: () => void;
}

export default function Login({ onLoginSso }: LoginProps) {
  // const { classes, cx } = useStyles();
  return <LoginTemplate onLoginSso={onLoginSso} />;
}
